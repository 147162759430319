<template>
  <div class="d-flex nowrap">
    <document-ref-icon
      :visible="showIcon"
      icon-name="i.Promises"
      :mixed="mixed"
      :x-small="_iconProps.xsmall"
      :small="_iconProps.small"
      :normal="_iconProps.normal"
      :large="_iconProps.large"
      :x-large="_iconProps.xlarge"
      :icon-mark-props="iconMarkProps"
    />
    <div class="d-flex flex-column flex-nowrap">
      <div>
        <span class="mr-1">{{$t('t.Number#')}}</span>
        <span class="mr-1">{{number}}</span>
        <span class="mr-1">{{$t('t.Of')|lowercase}}</span>
        <span class="primary--text">{{amount|currency(currency)}}</span>
        <span v-if="innerOptions.showDate">
          <span> {{$t('t.At')|lowercase}} </span>
          <span>{{targetDate|date}}</span>
        </span>
      </div>
      <div
        class="ref-detail d-flex flex-row"
        v-if="innerOptions.showDetail && canAccountAndDivision"
      >
        <document-name-ref
          :id="accountId"
          :cache-type="cacheTypeAccount"
          icon-name="i.Accounts"
          :show-icon="false"
          mixed
        />
        <span class="mx-1">-</span>

        <document-name-ref
          :id="companyId"
          :cache-type="cacheTypeCompany"
          :show-icon="false"
          icon-name="i.BusinessDivisions"
          mixed
        />
      </div>
      <slot name="doc-type" />
    </div>
  </div>
</template>

<script>
import ClientCache from '@/mixins/client-cache'

export default {
  inheritAttrs: false,
  components: {
    DocumentRefIcon: () => import('@/components/document-ref-icon'),
    DocumentNameRef: () => import('@/components/documents-ref/document-name-ref')
  },
  computed: {
    canAccountAndDivision () {
      return this.accountId || this.companyId
    },
    amount () {
      return this.cache?.doc?.amount
    },
    currency () {
      return this.cache?.doc?.currency
    },
    number () {
      return this.id ? this.cache?.doc?.number : this.$t('t.None')
    },
    targetDate () {
      return this.cache?.doc?.targetDate
    },
    cacheType () {
      return ClientCache.CacheType.PromiseRef
    },
    accountId () {
      return this.cache?.doc?.accountId
    },
    companyId () {
      return this.cache?.doc?.companyId
    },
    cacheTypeAccount () {
      return ClientCache.CacheType.AccountRef
    },
    cacheTypeCompany () {
      return ClientCache.CacheType.BusinessDivisionRef
    },
    innerOptions () {
      return {
        showDate: !!this.options?.showDate,
        showDetail: !!this.options?.showDetail
      }
    },
    _iconProps () {
      const _ = Object.assign({
        xsmall: false,
        small: false,
        normal: false,
        large: false,
        xlarge: false
      }, this.iconProps)

      return _
    }
  },
  mixins: [ClientCache],
  props: {
    id: String,
    options: Object,
    mixed: Boolean,
    iconProps: Object,
    showIcon: {
      type: Boolean,
      default: true
    },
    iconMarkProps: Object
  }
}
</script>

<style lang="stylus" scoped></style>
